<template>
  <div class="custom">
    <div class="header-block">
      <slot name="header" />
    </div>

    <main class="main-block">
      <slot name="content" />
    </main>

    <div class="footer-block">
      <slot name="footer" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header-block {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .main-block {
    flex-grow: 1;
    overflow-y: auto;
  }
  .footer-block {
    z-index: 1;
    position: sticky;
    bottom: 0;
  }
}
</style>
